<div class="d-flex justify-content-between">
  <div class="center">
    <h1 mat-dialog-title>Peças com NCM inválido</h1>
    <mat-icon
      (click)="showTooltip()"
      #tooltip="matTooltip"
      class="margin-top"
      [matTooltip]="closeOsDialogTooltip"
      [matTooltipHideDelay]="1000"
      color="warn"
      >help</mat-icon
    >
  </div>

  <div class="margin">
    <button
      mat-raised-button
      type="button"
      [disabled]="form.invalid"
      color="primary"
      (click)="save()"
    >
      Salvar
    </button>
    <button mat-icon-button type="button" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="table-container m-4">
  <table mat-table [dataSource]="invalidParts">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Código </th>
      <td mat-cell *matCellDef="let row"> {{ row.code }} </td>
    </ng-container>

    <ng-container matColumnDef="description" class="limit">
      <th mat-header-cell *matHeaderCellDef> Descrição </th>
      <td mat-cell *matCellDef="let row"> {{ truncateString(row.description) }} </td>
    </ng-container>

    <ng-container matColumnDef="ncm">
      <th mat-header-cell *matHeaderCellDef> NCM Atual </th>
      <td mat-cell *matCellDef="let row"> {{ row.NCM }} </td>
    </ng-container>

    <ng-container matColumnDef="newNcm">
      <th mat-header-cell *matHeaderCellDef> Novo NCM </th>
      <td mat-cell *matCellDef="let row">
        <form [formGroup]="form">
          <!-- group -->
          <!-- <mat-form-field class="col-12"> -->
              <input
                  money
                  formControlName="input"
                  [decimalPoints]="0"
                  [noClass]="true"
                  [thousandSeparator]="''"
                  type="text"
                  class="form-control"
                  placeholder="Adicione um NCM válido"
                  aria-describedby="basic-addon1"
                  (change)="onChange($event, row)"
                />
            <!-- </mat-form-field> -->
          </form>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</div>
