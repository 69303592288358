<h2 mat-dialog-title>Parte do veículo danificada:</h2>
<mat-dialog-content>
  <form autocomplete="off"
        [formGroup]="formController" 
        (submit)="confirm()" 
        class="w-100">
  <mat-form-field class="w-100" *ngIf="!isMobile">
    <mat-label>Nome da parte do veículo (opcional)</mat-label>
    <input #vehiclePartInput
          matInput 
          formControlName="vehiclePartName" 
          cdkFocusInitial
          placeholder="Descrição"
          >
  </mat-form-field>
  <div *ngIf="isMobile" class="form-group w-100">
    <label>Nome da parte do veículo (opcional)</label>
    <input  #vehiclePartInput
            matInput 
            formControlName="vehiclePartName" 
            placeholder="Descrição">
  </div>
  
  <mat-radio-group formControlName="code" class="d-flex flex-column justify-content-center align-items-start">
    <div class="d-flex flex-row gap-1 align-items-center mb-2">
      <p class="codes-type-title m-0">Tipo de avaria *</p>

      @if (formController.controls?.code?.errors?.['required']) {
        <mat-error class="m-0">
          O tipo é obrigatório
        </mat-error>
      }
    </div>

    @for (option of availableCodes; track $index) {
      <mat-radio-button [value]="option.value" color="primary">
        <img class="mb-fix-align" [src]="'./assets/icons/' + option.key + '.png'" [alt]="'icone de ' + option.value">
        <span> {{ option.value }} </span>
      </mat-radio-button>
    }
  </mat-radio-group>
  
</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button 
         mat-raised-button
         (click)="cancel()"
         color="warn"
         >Cancelar</button>
  <button [disabled]="formController.invalid"
          mat-raised-button
          (click)="confirm()"  
          color="primary"
          type="button">Confirmar</button>
</mat-dialog-actions>