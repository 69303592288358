import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { Part } from 'src/app/interface/part';
import { LayoutService } from 'src/app/service/layout.service';
import { PartService } from 'src/app/service/part.service';
import { SnackbarService } from 'src/app/service/snackbar.service';

@Component({
  selector: 'app-validate-ncm-before-closing-os',
  templateUrl: './validate-ncm-before-closing-os.component.html',
  styleUrls: ['./validate-ncm-before-closing-os.component.scss'],
})
export class ValidateNcmBeforeClosingOsComponent implements OnInit {
  @ViewChild(MatTooltip) tooltip: MatTooltip;

  form: FormGroup = new FormBuilder().group({
    input: ["", Validators.required],
  });
  public invalidParts: Part[] = [];
  columns = ['name', 'description', 'ncm', 'newNcm'];
  partsChangeNcm: partsChangeNcm[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ValidateNcmBeforeClosingOsComponent>,
    private partService: PartService,
    private layout: LayoutService
  ) {
    
  }

  ngOnInit() {
    this.invalidParts = this.data.invalidParts;
  }

  get input() {
    return this.form.get('input').value;
  }

  close(partsChanged?) {
    if (partsChanged) {
      this.dialogRef.close(partsChanged);
    } else {
      this.dialogRef.close();
    }
  }

  truncateString(text: string, maxLength: number = 30): string {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  onChange(event: Event, part: Part) {
    const inputElement = event.target as HTMLInputElement;
    const newNcm = Number(inputElement.value);
    const partChanged = {
      part: part,
      newNcm: newNcm,
    };
    const index = this.partsChangeNcm.findIndex((p) => p.part.id === part.id);

    if (index !== -1) {
      this.partsChangeNcm[index].newNcm = newNcm;
    } else {
      this.partsChangeNcm.push(partChanged);
    }
  }

  save(){
    let partsChanged: Part[] = [] 
    let partsContinueInvalid: Part[] = [] 
    try{
      this.partsChangeNcm.map(part => {
        if(part.part.NCM === part.newNcm){
          partsContinueInvalid.push(part.part);
        } else {
          part.part.NCM = part.newNcm;
          partsChanged.push(part.part);
        }
      });
      this.partService.addOrUpdateMany(partsChanged);

      if(partsChanged.length != this.invalidParts.length || partsContinueInvalid.length){
        this.close();
      } else {
        this.close(partsChanged);
      }
    } catch (error) {
        console.error(error)
    } finally {
      this.layout.loader = true;
    }
  }

  showTooltip() {
    this.tooltip.show();
  }

  get closeOsDialogTooltip(): string {
    return 'Alguma(s) peça(s) na OS contém NCM inválido. \nPara continuar o processo de fechamento da OS é necessário corrigir o NCM da(s) mesma(s).';
  }
}

export interface partsChangeNcm {
  part: Part;
  newNcm: number;
}
